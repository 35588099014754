import React , { useState, useEffect} from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// @material-ui/icons
import Camera from '@material-ui/icons/Camera'
import Palette from '@material-ui/icons/Palette'
import Favorite from '@material-ui/icons/Favorite'
// core components
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Parallax from 'components/Parallax/Parallax.js'


import styles from 'assets/jss/material-kit-react/views/heritagePage.js'

// import styles from 'assets/jss/material-kit-react/views/landingPage.js'
import ProductSection from '../LandingPage/Sections/ProductSection.js'

import FoodSection from 'views/LandingPage/Sections/FoodSection.js';
import Zomato from 'zomato.js'

const dashboardRoutes = []

const useStyles = makeStyles(styles)

export default function FoodPage(props) {
  const classes = useStyles()
  const {...rest } = props
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  )
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery)

  const initialPageData = {
    version: 1,
    releaseDate: '2020-02-09T00:00:00.000Z',
    pageTitle: '',
    data: []
}
  const [title, setTitle] = useState('Food and Fun')
  const [subTitle, setSubTitle] = useState('Culinary Delights')
  const [description, setDescription] = useState('Pune in western India has a dining scene to rival many                  international cities, offering diners plenty of pan-Asian and                  world-fusion cuisine, as well as traditional Maharashtrian                  dishes and Pune’s most famous culinary experience.')
  const [pageData,setPageData ] = useState(initialPageData)
          
  const apiKey = 'ebd261e20e22dc144609f29f586a7d16';
  const zomato = new Zomato(apiKey);
  let newData  = {}

  useEffect(() => {
    
  zomato
  .search({
    entity_id:5,
    entity_type:'city',
    collection_id: 2,
    count: 20
  })
  .then(function(data) {
      newData = {
      pageTitle: 'Legendary Food Joints', data : data.restaurants
    }
    console.log(data.restaurants);
    setPageData(newData)    
  })
  .catch(function(err) {
    console.error(err);
  });
  }, []);



  return (
    <div>
      <Header
        color='transparent'
        routes={dashboardRoutes}
        brand='VisitPune.in'
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white'
        }}
        {...rest}
      />
      <Parallax small filter image={require('assets/img/profile-bg.jpg')} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection title={title} description={description} />
          <FoodSection pageData={pageData} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

/*eslint-disable*/
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
// react components for routing our app without refresh
import { Link } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'

// @material-ui/icons
import {
  Apps,
  CloudDownload,
  AccountBalance,
  Fastfood,
  MusicNote,
  LocalActivity
} from '@material-ui/icons'

// core components
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js'
import Button from 'components/CustomButtons/Button.js'

import styles from 'assets/jss/material-kit-react/components/headerLinksStyle.js'

const useStyles = makeStyles(styles)

export default function HeaderLinks(props) {
  const classes = useStyles()
  return (
    <List className={classes.list}>
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText='Heritage Sites'
          buttonProps={{
            className: classes.navLink,
            color: 'transparent'
          }}
          buttonIcon={AccountBalance}
          // dropdownList={[
          //   <Link to="/" className={classes.dropdownLink}>
          //     All components
          //   </Link>,
          //   <a
          //     href="https://creativetimofficial.github.io/material-kit-react/#/documentation?ref=mkr-navbar"
          //     target="_blank"
          //     className={classes.dropdownLink}
          //   >
          //     Documentation
          //   </a>
          // ]}
        />
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Button
          href='/heritage-page'
          color='transparent'
          className={classes.navLink}
        >
          <AccountBalance className={classes.icons} /> Heritage Sites
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href='/food-page'
          color='transparent'
          className={classes.navLink}
        >
          <Fastfood className={classes.icons} /> Food and Fun
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button href='/nightlife-page' color='transparent' className={classes.navLink}>
          <MusicNote className={classes.icons} /> Nightlife
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button href='/event-page' color='transparent' className={classes.navLink}>
          <LocalActivity className={classes.icons} /> Events
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
        <Tooltip
          id='instagram-twitter'
          title='Follow us on twitter'
          placement={window.innerWidth > 959 ? 'top' : 'left'}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href=''
            target='_blank'
            color='transparent'
            className={classes.navLink}
          >
            <i className={classes.socialIcons + ' fab fa-twitter'} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id='instagram-facebook'
          title='Follow us on facebook'
          placement={window.innerWidth > 959 ? 'top' : 'left'}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color='transparent'
            href=''
            target='_blank'
            className={classes.navLink}
          >
            <i className={classes.socialIcons + ' fab fa-facebook'} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id='instagram-tooltip'
          title='Follow us on instagram'
          placement={window.innerWidth > 959 ? 'top' : 'left'}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color='transparent'
            href='https://www.instagram.com/visitpune.in/?hl=en'
            target='_blank'
            className={classes.navLink}
          >
            <i className={classes.socialIcons + ' fab fa-instagram'} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  )
}

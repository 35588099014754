import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'

import SectionItem from '../../../views/Components//Sections/SectionItem'

import styles from 'assets/jss/material-kit-react/views/landingPageSections/teamStyle.js'

import team1 from 'assets/img/categories/heritage.jpg'
import team2 from 'assets/img/categories/food.jpg'
import team3 from 'assets/img/categories/mightlife.jpg'

const useStyles = makeStyles(styles)

export default function TeamSection({ pageData }) {
  const classes = useStyles()
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  )
  const { pageTitle, data } = pageData
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>{pageTitle}</h2>
      <div>
        <GridContainer>
          {data.map(item => (
            <SectionItem key={item.id} data={item} />
          ))}
        </GridContainer>
      </div>
    </div>
  )
}

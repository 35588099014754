import React , { useState, useEffect} from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// @material-ui/icons
// core components
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Parallax from 'components/Parallax/Parallax.js'
import axios from 'axios'

import styles from 'assets/jss/material-kit-react/views/heritagePage.js'

// import styles from 'assets/jss/material-kit-react/views/landingPage.js'
import ProductSection from '../LandingPage/Sections/ProductSection.js'

import FoodSection from 'views/LandingPage/Sections/FoodSection.js';
import EventSection from 'views/LandingPage/Sections/EventSection.js';

const dashboardRoutes = []

const useStyles = makeStyles(styles)

export default function EventPage(props) {
  const classes = useStyles()
  const {...rest } = props
  
  const initialPageData = {
    version: 1,
    releaseDate: '2020-02-09T00:00:00.000Z',
    pageTitle: 'Interesting Events',
    data: []
}
  const [title, setTitle] = useState('Interesting Events')
  const [subTitle, setSubTitle] = useState('')
  const [description, setDescription] = useState('Explore all upcoming events in Pune, live concerts, conferences, college events, exhibitions, things to do in Pune with MeraEvents also Book your tickets online for all popular Pune events happening in and nearby the Pune which matches your mood.')
  const [pageData,setPageData ] = useState(initialPageData)
          
  
  let newData  = {}
  useEffect(() => {
    newData = getEventData(newData, setPageData);
  }, []);
  

  return (
    <div>
      <Header
        color='transparent'
        routes={dashboardRoutes}
        brand='VisitPune.in'
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white'
        }}
        {...rest}
      />
      <Parallax small filter image={require('assets/img/profile-bg.jpg')} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection title={title} description={description} />
          <EventSection pageData={pageData} />
        </div>
      </div>
      <Footer />
    </div>
  )
}
function getEventData(newData, setPageData) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer 882329d2cd10d5dcc5eb2066c2690191e58f4f3e");
    var raw = JSON.stringify({ "keyWord": "pune", "limit": 20, "page": 1 });
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'manual'
    };
    fetch("https://www.meraevents.com/developer/event/eventSearch", requestOptions)
        .then(response => response.text())
        .then(result => {
            //console.log("result", JSON.parse(result))
            const data = JSON.parse(result);
            console.log(data, data.length);
            if (data !== null)
                newData = {
                    pageTitle: '', data: data.response.eventList
                };
            setPageData(newData);
            console.log("newData", newData);
        })
        .catch(error => console.log('error', error));
    return newData;
}


import React, { useState, useEffect } from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'

import styles from 'assets/jss/material-kit-react/views/landingPageSections/teamStyle.js'

const useStyles = makeStyles(styles)

export default function SectionItem({ data }) {
  const classes = useStyles()
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  )

  const img = require(`assets/img/categories/${data.imgSrc}.jpg`)

  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card plain>
        <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
          <a href={`${data.url}`}>
            <img src={img} alt='...' className={imageClasses} />
          </a>
        </GridItem>
        <h4 className={classes.cardTitle}>
          {data.title}
          <br />
          <small className={classes.smallTitle}>{data.subTitle}</small>
        </h4>
        <CardBody>
          <p className={classes.description}>{data.description}</p>
        </CardBody>
        <CardFooter className={classes.justifyCenter}>
          <Button justIcon color='transparent' className={classes.margin5}>
            <i className={classes.socials + ' fab fa-twitter'} />
          </Button>
          <Button justIcon color='transparent' className={classes.margin5}>
            <i className={classes.socials + ' fab fa-instagram'} />
          </Button>
          <Button justIcon color='transparent' className={classes.margin5}>
            <i className={classes.socials + ' fab fa-facebook'} />
          </Button>
        </CardFooter>
      </Card>
    </GridItem>
  )
}

import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons

// core components
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Parallax from 'components/Parallax/Parallax.js'

import styles from 'assets/jss/material-kit-react/views/landingPage.js'

// Sections for this page
import ProductSection from './Sections/ProductSection.js'
import TeamSection from './Sections/TeamSection.js'
import WorkSection from './Sections/WorkSection.js'

const dashboardRoutes = []

const useStyles = makeStyles(styles)

export default function LandingPage(props) {
  const classes = useStyles()
  const { ...rest } = props

  const description = `Pune, situated 1,837 feet above sea level on the Deccan plateau, at
  the convergence of two rivers Mula and Mutha. It is on the sheltered
  side of the Sahyadri mountain range, which forms a barrier from the
  Arabian Sea and is a hilly city. Once the centre of power of the
  Maratha Empire, established by Chatrapati Shivaji Maharaj, this city has now
  come to be known as the cultural capital of Maharashtra. It
  epitomises Maratha culture, and places emphasis on education, arts
  and crafts, music, and theatre. Pune culture reflects a blend of
  tradition with modernity.`
  const title = `About Pune`

  const pageData = {
    version: 1,
    releaseDate: '2020-02-09T00:00:00.000Z',
    pageTitle: "What's to do in Pune.",
    data: [
      {
        id: 1,
        title: 'Heritage Sites',
        subTitle: 'Flashback in Time',
        description:
          'Visit these magnificent historical places in Pune and know                   their historical & cultural significance, admire their                   architecture.These fascinating monuments and heritage sites                   stand quietly today to retell their stories of joy, sorrow,                   courage and sacrifices. The rich heritage is amply reflected                   in the various monuments throughout the city.',
        imgSrc: 'heritage',
        url: 'heritage-page'
      },
      {
        id: 2,
        title: 'Food and Fun',
        subTitle: 'Culinary Delights',
        description:
          'Pune in western India has a dining scene to rival many                  international cities, offering diners plenty of pan-Asian and                  world-fusion cuisine, as well as traditional Maharashtrian                  dishes and Pune’s most famous culinary experience.',
        imgSrc: 'food',
        url: 'food-page'
      },
      {
        id: 3,
        title: 'Night Life',
        subTitle: 'Funtastic Experience',
        description:
          'When the sun goes down in the City, the party doesn’t stop in                  the city with the highest number of pubs in India. it’s just                  getting started. Listen in free live music at one of the many                  Hot Destinations, sip on a craft cocktail at a at a rooftop                  bar, or see one of your favorite bands perform a sell-out                  show. There’s no wrong way to spend your nights.',
        imgSrc: 'mightlife',
        url: 'nightlife-page'
      }
    ]
  }

  return (
    <div>
      <Header
        color='transparent'
        routes={dashboardRoutes}
        brand='Visit Pune'
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white'
        }}
        {...rest}
      />
      <Parallax filter image={require('assets/img/landing-bg.jpg')}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>
                VisitPune.in - Your definitive guide to the city.
              </h1>
              <h4>
                Pune the second largest city in the Maharashtra after the
                capital, Mumbai.Also called as the Oxford of the East.The
                cultural capital of Maharashtra.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection title={title} description={description} />
          <TeamSection pageData={pageData} />
          {/* <WorkSection /> */}
        </div>
      </div>
      <Footer />
    </div>
  )
}

import React from 'react'
// nodejs library that concatenates classes

import SectionPage from 'views/SectionPage/SectionPage.js'

export default function HeritagePage(props) {
  const { ...rest } = props

  const description = `Visit these magnificent historical places in Pune and know                   their historical & cultural significance, admire their                   architecture.These fascinating monuments and heritage sites                   stand quietly today to retell their stories of joy, sorrow,                   courage and sacrifices. The rich heritage is amply reflected                   in the various monuments throughout the city.`
  const title = `Heritage Sites`

  const pageData = {
    version: 1,
    releaseDate: '2020-02-09T00:00:00.000Z',
    pageTitle: '',
    data: [
      {
        id: 1,
        title: 'Shaniwarwada',
        subTitle: "Bastion of Peshwa's",
        description:
          'The Shaniwarwada was originally the seven storeyed           capital building of the Peshwas of the Maratha Empire.          It was supposed to be made entirely of stone but after          the completion of the base floor or the first storey,          the people of Satara (the national capital) complained          to the Shahu(King) saying that a stone monument can be          sanctioned and built only by the Shahu(King) himself          and not the Peshwas. Following this, an official          letter was written to the Peshwas stating that the          remaining building had to be made of brick and not          stone. Even today if you visit and see the walls          carefully, they are partly made of stone and partly by          bricks. The Wada was then completed and upon being          attacked by the British Artillery 90 years later, all          the top six stories collapsed leaving only the stone          base, which was immune to the British artillery. Hence          only the stone base of the Shaniwar Wada remains and          can be seen even today in the older parts of Pune.',
        imgSrc: 'shaniwarwada',
        url: '/site/shaniwarwada'
      },
      {
        id: 2,
        title: 'Aga Khan Palace',
        subTitle: 'An icon of Freedom Fight.',
        description:
          'The Aga Khan Palace is also known as the Gandhi           National Museum and is a part of the Indian freedom          struggle. It served as a prison for national leaders          like Mahatma Gandhi during the Quit India Movement in          1942.The Aga Khan Palace was built by Sultan Muhammed          Shah Aga Khan III in Pune, India. The palace was an          act of charity by the Sultan who wanted to help the          poor in the neighbouring areas of Pune, who were          drastically hit by famine. Aga Khan Palace is a          majestic building.The palace is closely linked to the          Indian freedom movement as it served as a prison for          Mahatma Gandhi, his wife Kasturba Gandhi, his          secretary Mahadev Desai and Sarojini Naidu. It is also          the place where Kasturba Gandhi and Mahadev Desai          died.In 2003, Archaeological Survey of India (ASI)          declared the place as a monument of national          importance.',
        imgSrc: 'aga-khan-palace',
        url: '/site/aga-khan-palace'
      },
      {
        id: 3,
        title: 'Sinhgad',
        subTitle: 'The epitome of sacrifice.',
        description:
          'Sinhagad is a hill fortress located at around 35 km                          southwest of the city of Pune, India. Some of the                          information available at this fort suggests that the                          fort could have been built 2000 years ago. The caves                          and the carvings in the Kaundinyeshwar temple stand as                          proofs for the same. One of the most famous battles on                          Sinhgad was fought by Tanaji Malusare, general of                          Chhatrapati Shivaji of the Maratha Empire in order to                          recapture the fort on March 1670. A steep cliff                          leading to the fort was scaled in the dead of the                          night with the help of a tamed monitor lizard named                          "Yashwanti", colloquially known as a Ghorpad.                          Thereafter, A fierce battle ensued between Tanaji and                          his men versus the Mughal army headed by Udaybhan                          Singh Rathod, a Rajput sardar who had control of the                          fort. Tanaji Malusare lost his life, but his brother                          Suryaji took over and captured the Kondana fort, now                          known as Sinhagad. There is an anecdote that upon                          hearing of Tanaji\'s death, Chhatrapati Shivaji Maharaj                          expressed his remorse with the words, "Gad aala, pan                          Sinha gela" - "The Fort is conquered, but the Lion was                          lost"',
        imgSrc: 'sinhgad',
        url: '/site/sinhgad'
      }
    ]
  }

  return (
    <SectionPage title={title} description={description} pageData={pageData} />
  )
}

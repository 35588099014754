import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import styles from 'assets/jss/material-kit-react/views/landingPageSections/teamStyle.js'

import EventItem from './EventItem'


const useStyles = makeStyles(styles)

export default function EventSection({ pageData }) {
  const classes = useStyles()
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  )
  const { pageTitle, data } = pageData
  console.log(pageData)
  if(data != null){
    return (
      <div className={classes.section}>
        <h2 className={classes.title}>{pageTitle}</h2>
        <div>
          <GridContainer>
            {data.map(item => (
              <EventItem key={item.id} data={item} />
            ))}
          </GridContainer>
        </div>
      </div>
    )
  }else{
    return (<div></div>)
  }
  
}

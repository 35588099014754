import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// @material-ui/icons
import Camera from '@material-ui/icons/Camera'
import Palette from '@material-ui/icons/Palette'
import Favorite from '@material-ui/icons/Favorite'
// core components
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import NavPills from 'components/NavPills/NavPills.js'
import Parallax from 'components/Parallax/Parallax.js'

import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'

import styles from 'assets/jss/material-kit-react/views/heritagePage.js'

// import styles from 'assets/jss/material-kit-react/views/landingPage.js'
import ProductSection from '../LandingPage/Sections/ProductSection.js'
import TeamSection from '../LandingPage/Sections/TeamSection.js'
import WorkSection from '../LandingPage/Sections/WorkSection.js'

const dashboardRoutes = []

const useStyles = makeStyles(styles)

export default function SectionPage(props) {
  const classes = useStyles()
  const { title, description, pageData, ...rest } = props
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  )
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery)

  return (
    <div>
      <Header
        color='transparent'
        routes={dashboardRoutes}
        brand='VisitPune.in'
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white'
        }}
        {...rest}
      />
      <Parallax small filter image={require('assets/img/profile-bg.jpg')} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection title={title} description={description} />
          <TeamSection pageData={pageData} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'



import 'assets/scss/material-kit-react.scss?v=1.8.0'

// pages for this product
import Components from 'views/Components/Components.js'
import LandingPage from 'views/LandingPage/LandingPage.js'
import ProfilePage from 'views/ProfilePage/ProfilePage.js'
import LoginPage from 'views/LoginPage/LoginPage.js'
import HeritagePage from 'views/HeritagePage/HeritagePage'
import NightlifePage from 'views/NightlifePage/NightlifePage'
import FoodPage from 'views/FoodPage/FoodPage'
import EventPage from 'views/EventPage/EventPage'

var hist = createBrowserHistory()

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/* <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} /> */}
      <Route path='/heritage-page' component={HeritagePage} />
      <Route path='/food-page' component={FoodPage} />
      <Route path='/nightlife-page' component={NightlifePage} />
      <Route path='/event-page' component={EventPage} />

      {/* <Route path="/" component={Components} /> */}
      <Route path='/' component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById('root')
)

import React, { useState, useEffect } from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'

import styles from 'assets/jss/material-kit-react/views/landingPageSections/teamStyle.js'
import * as moment from 'moment';

const useStyles = makeStyles(styles)

export default function EventItem({ data }) {
  const classes = useStyles()
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  )

  var imgStyle = {
    height:"145px",
    width:"145px"
  };

  //const img = require(`assets/img/categories/${data.imgSrc}.jpg`)

  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card plain>
        <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
          <a href={`${data.eventUrl}`} target="_blank">
            <img src={data.thumbImage} alt='...' className={imageClasses} style={imgStyle}/>
          </a>
        </GridItem>
        <h4 className={classes.cardTitle}>
          {data.name}
          <br />
          <small className={classes.smallTitle}>{data.subTitle}</small>
        </h4>
        <CardBody>
          <p className={classes.description}>{data.title}</p>
          <p><b>Venue : </b> {data.venueName}</p>
          
          <p><b>Timings : </b>{moment(data.startDate).format("ddd, MMMM Do YYYY, h:mm a")} to  {moment(data.endDate).format("ddd, MMMM Do YYYY, h:mm a")}</p>
          {/* <p>{moment(data.startDate).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p> */}
          <a href={`${data.eventUrl}`} target="_blank">
          <Button color="primary" size="lg" simple > 
              {data.booknowButtonValue}
            </Button>
          </a>

        </CardBody>
        
      </Card>
    </GridItem>
  )
}

import React , { useState, useEffect } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// @material-ui/icons
import Camera from '@material-ui/icons/Camera'
import Palette from '@material-ui/icons/Palette'
import Favorite from '@material-ui/icons/Favorite'
// core components
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import NavPills from 'components/NavPills/NavPills.js'
import Parallax from 'components/Parallax/Parallax.js'

import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'

import styles from 'assets/jss/material-kit-react/views/heritagePage.js'

// import styles from 'assets/jss/material-kit-react/views/landingPage.js'
import ProductSection from '../LandingPage/Sections/ProductSection.js'

import Zomato from 'zomato.js'
import NightLifeSection from 'views/LandingPage/Sections/NightLifeSection.js';

const dashboardRoutes = []

const useStyles = makeStyles(styles)

export default function NightLifePage(props) {
  const classes = useStyles()
  const {...rest } = props
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  )
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery)

  const initialPageData = {
    version: 1,
    releaseDate: '2020-02-09T00:00:00.000Z',
    pageTitle: '',
    data: []
}


  const [title, setTitle] = useState('NightLife')
  const [subTitle, setSubTitle] = useState('Funtastic Experience')
  const [description, setDescription] = useState('When the sun goes down in the City, the party doesn’t stop in                  the city with the highest number of pubs in India. it’s just                  getting started. Listen in free live music at one of the many                  Hot Destinations, sip on a craft cocktail at a at a rooftop                  bar, or see one of your favorite bands perform a sell-out                  show. There’s no wrong way to spend your nights.')
  const [pageData,setPageData ] = useState(initialPageData)
          
  const apiKey = 'ebd261e20e22dc144609f29f586a7d16';
  const zomato = new Zomato(apiKey);
  let newData  = {}
  useEffect(() => {
  zomato
  .search({
    entity_id:5,
    entity_type:'city',
    collection_id: 75,
    category:3,
    count: 20
  })
  .then(function(data) {
      newData = {
      pageTitle: 'Amazing Night Life', data : data.restaurants
    }
    console.log(data.restaurants);
    setPageData(newData)    
  })
  .catch(function(err) {
    console.error(err);
  });
}, []);


  return (
    <div>
      <Header
        color='transparent'
        routes={dashboardRoutes}
        brand='VisitPune.in'
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white'
        }}
        {...rest}
      />
      <Parallax small filter image={require('assets/img/profile-bg.jpg')} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection title={title} description={description} />
          <NightLifeSection pageData={pageData} />
        </div>
      </div>
      <Footer />
    </div>
  )
}
